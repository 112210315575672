export const curiousBlue = '#168eea'
export const curiousBlueLight = 'rgba(22, 142, 234, .3)'
export const curiousBlueUltraLight = 'rgba(22, 142, 234, .1)'
export const denim = '#137ac9'
export const toryBlue = '#0f63a4'

export const appdotnet = '#4a484c'
export const facebook = '#3b5998'
export const googleplus = '#dd4b39'
export const instagram = '#e53c5f'
export const linkedin = '#007bb6'
export const pinterest = '#bd081c'
export const twitter = '#55acee'
export const tiktok = '#000000'
export const googleBusiness = '#478af5'
export const shopify = '#95bf47'
export const shopifyShade = '#5e8e3e'

export const aquaHaze = '#f4f7f9'
export const geyser = '#ced7df'
export const fillColor = '#eaeff2'
export const mystic = '#e6ebef'
export const nevada = '#666c72'
export const lightSlate = '#8A9097'
export const outerSpace = '#323b43'
export const outerSpaceUltraDark = 'rgba(50, 59, 67, 0.8)'
export const outerSpaceLight = 'rgba(50, 59, 67, 0.3)'
export const outerSpaceUltraLight = 'rgba(50, 59, 67, 0.1)'
export const shuttleGray = '#59626a'
export const grayLight = '#e0e0e0'

export const black = '#000'
export const white = '#fff'
export const offWhite = '#fcfcfc'
export const transparent = 'transparent'

export const shamrock = '#2fd566'
export const saffron = '#f1cb3a'
export const torchRed = '#ff1e1e'

// TODO: Do we need to keep these colors here?
// Should they have cuter names?
export const sidebarBackgroundBlue = '#192534'
export const sidebarIcon = '#7088A5'
export const darkPopover = '#2E435E'

export const baliHai = '#8D969E'

export const yellowLight = '#FCDFA0'
export const yellowUltraLight = '#FFF4DA'

const colors = {
  curiousBlue,
  curiousBlueLight,
  curiousBlueUltraLight,
  denim,
  toryBlue,
  appdotnet,
  facebook,
  googleplus,
  instagram,
  linkedin,
  pinterest,
  twitter,
  googleBusiness,
  tiktok,
  aquaHaze,
  geyser,
  mystic,
  nevada,
  lightSlate,
  outerSpace,
  outerSpaceUltraDark,
  outerSpaceLight,
  outerSpaceUltraLight,
  shuttleGray,
  black,
  white,
  offWhite,
  transparent,
  shamrock,
  saffron,
  torchRed,
  sidebarBackgroundBlue,
  sidebarIcon,
  darkPopover,
  baliHai,
  yellowLight,
  yellowUltraLight,
  grayLight,
}

export default colors
