import React from 'react'
import Icon from '../index'

const WarningIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M7.28522294,0.582295182 C7.68011914,-0.193881453 8.32015168,-0.194315304 8.71526856,0.582295182 L15.8443277,14.5946078 C16.2392239,15.3707845 15.8563826,16 14.9948436,16 L1.00564792,16 C0.141594621,16 -0.238953082,15.3712184 0.156163853,14.5946078 L7.28522294,0.582295182 Z M8.00063465,13.6403459 C8.4327265,13.6403459 8.78300622,13.2881948 8.78300622,12.8537945 C8.78300622,12.4193942 8.4327265,12.0672431 8.00063465,12.0672431 C7.56854277,12.0672431 7.21826309,12.4193942 7.21826309,12.8537945 C7.21826309,13.2881948 7.56854277,13.6403459 8.00063465,13.6403459 Z M7.3863128,5.77483204 C7.38259314,5.77483204 7.37887467,5.77496538 7.37516447,5.77523181 C7.2889657,5.78142176 7.22407908,5.85669092 7.23023613,5.94335021 L7.59906844,11.1345893 C7.60491729,11.2169107 7.67305263,11.2806917 7.75514511,11.2806917 L8.24612423,11.2806917 C8.32821668,11.2806917 8.39635201,11.2169107 8.40220086,11.1345893 L8.77103319,5.94335021 C8.77129818,5.93962018 8.77143079,5.93588184 8.77143079,5.93214231 C8.77143079,5.84526225 8.7013749,5.77483204 8.61495648,5.77483204 L7.3863128,5.77483204 Z" />
  </Icon>
)

WarningIcon.propTypes = {
  ...Icon.propTypes,
}

export default WarningIcon
