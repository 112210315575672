import React, { ReactNode } from 'react'
import { Dialog, DialogContentProps } from '../Dialog'
import { Flex } from '../Flex'
import styles from './UpgradeDialog.module.css'

// Dialog trigger doesn't need additional props, so we can use it directly.
const UpgradeDialogTrigger = Dialog.Trigger
UpgradeDialogTrigger.displayName = 'UpgradeDialog.Trigger'

interface UpgradeDialogProps {
  children: ReactNode
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

/**
 * This dialog is used to show upgrade options to the user. It has standardised layout to make sure the content is consistent.
 */
const UpgradeDialog = ({
  children,
  open,
  onOpenChange,
}: UpgradeDialogProps): JSX.Element => (
  <Dialog open={open} onOpenChange={onOpenChange}>
    <Dialog.Body className={styles.dialogBody}>{children}</Dialog.Body>
  </Dialog>
)

UpgradeDialog.displayName = 'UpgradeDialog'

interface UpgradeDialogTextContentProps {
  children: ReactNode
  className?: string
}

const UpgradeDialogTextContent = React.forwardRef<
  HTMLDivElement,
  UpgradeDialogTextContentProps
>(({ children, className }, ref) => (
  <Flex
    gap="space-200"
    direction="column"
    align="start"
    justify="center"
    className={`${styles.textContent} ${className || ''}`}
    ref={ref}
  >
    {children}
  </Flex>
))

UpgradeDialogTextContent.displayName = 'UpgradeDialog.TextContent'

interface UpgradeDialogContentProps {
  children: ReactNode
  className?: string
  size?: DialogContentProps['size']
}

const UpgradeDialogContent = React.forwardRef<
  HTMLDivElement,
  UpgradeDialogContentProps
>(({ children, className, size = 'large' }, ref) => (
  <Dialog.Content
    size={size}
    className={`${styles.dialogContent} ${className || ''}`}
    ref={ref}
  >
    {children}
    <Dialog.CloseButton />
  </Dialog.Content>
))

UpgradeDialogContent.displayName = 'UpgradeDialog.Content'

type UpgradeDialogImageProps = {
  children: ReactNode
  className?: string
}

const UpgradeDialogImage = React.forwardRef<
  HTMLDivElement,
  UpgradeDialogImageProps
>(({ children, className, ...props }, ref) => (
  <div className={`${styles.image} ${className || ''}`} {...props} ref={ref}>
    {children}
  </div>
))

UpgradeDialogImage.displayName = 'UpgradeDialog.Image'

const UpgradeDialogObject = Object.assign(UpgradeDialog, {
  Trigger: UpgradeDialogTrigger,
  Content: UpgradeDialogContent,
  TextContent: UpgradeDialogTextContent,
  Image: UpgradeDialogImage,
})

export { UpgradeDialogObject as UpgradeDialog }
export type {
  UpgradeDialogProps,
  UpgradeDialogTextContentProps,
  UpgradeDialogContentProps,
  UpgradeDialogImageProps,
}
