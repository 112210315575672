import React from 'react'
import Icon from '../index'

const ReplyIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <g transform="translate(8.066952, 8.472268) rotate(-45.000000) translate(-8.066952, -8.472268) translate(0.066952, 1.972268)">
      <path d="M0.0215566657,3.31257555 C0.0215566657,3.03718605 0.238778853,2.81393875 0.523178151,2.81393875 L10.4217198,2.81393875 L10.4217197,0.427889852 C10.4217197,0.149934187 10.5821386,0.0844435872 10.7695555,0.271180514 L15.63441,5.11838601 C15.8265144,5.3097935 15.7566205,5.46495993 15.4870722,5.46495993 L0.516730268,5.46495988 C0.243253439,5.46495988 0.0215566657,5.23893531 0.0215566657,4.96632308 L0.0215566657,3.31257555 Z" />
      <path d="M15.984894,9.45772119 C15.984894,9.73311069 15.7676718,9.95635798 15.4832725,9.95635798 L5.58473083,9.95635798 L5.58473093,12.3996933 C5.58473094,12.6753679 5.42431198,12.7374758 5.23689513,12.5489472 L0.372040608,7.65523588 C0.179936192,7.46199196 0.249830113,7.30533681 0.519378395,7.30533681 L15.4897204,7.30533686 C15.7631972,7.30533686 15.984894,7.53136143 15.984894,7.80397365 L15.984894,9.45772119 Z" />
    </g>
  </Icon>
)

ReplyIcon.propTypes = {
  ...Icon.propTypes,
}

export default ReplyIcon
