import React from 'react'
import Icon from '../index'

const AnalyzeIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <polygon
      fillRule="nonzero"
      points="11.3146503 9.57492875 15.4856547 1.64892397 14.069743 0.903810408 10.4735244 7.73757125 4.65360704 6.38869876 0.735225826 14.342021 2.1704915 15.0491366 5.52821115 8.23381544"
    ></polygon>
    <path d="M1.45454545,16 C0.651222182,16 0,15.3487778 0,14.5454545 C0,13.7421313 0.651222182,13.0909091 1.45454545,13.0909091 C2.25786873,13.0909091 2.90909091,13.7421313 2.90909091,14.5454545 C2.90909091,15.3487778 2.25786873,16 1.45454545,16 Z M5.09090909,8.72727273 C4.28758582,8.72727273 3.63636364,8.07605055 3.63636364,7.27272727 C3.63636364,6.469404 4.28758582,5.81818182 5.09090909,5.81818182 C5.89423236,5.81818182 6.54545455,6.469404 6.54545455,7.27272727 C6.54545455,8.07605055 5.89423236,8.72727273 5.09090909,8.72727273 Z M10.9090909,10.1818182 C10.1057676,10.1818182 9.45454545,9.530596 9.45454545,8.72727273 C9.45454545,7.92394945 10.1057676,7.27272727 10.9090909,7.27272727 C11.7124142,7.27272727 12.3636364,7.92394945 12.3636364,8.72727273 C12.3636364,9.530596 11.7124142,10.1818182 10.9090909,10.1818182 Z M14.5454545,2.90909091 C13.7421313,2.90909091 13.0909091,2.25786873 13.0909091,1.45454545 C13.0909091,0.651222182 13.7421313,0 14.5454545,0 C15.3487778,0 16,0.651222182 16,1.45454545 C16,2.25786873 15.3487778,2.90909091 14.5454545,2.90909091 Z"></path>
  </Icon>
)

AnalyzeIcon.propTypes = {
  ...Icon.propTypes,
}

export default AnalyzeIcon
