import React from 'react'
import Icon from '../index'

const CircleFacebookIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <g fillRule="evenodd">
      <path d="M0,8 C0,3.581722 3.59071231,0 8,0 C12.418278,0 16,3.59071231 16,8 C16,12.418278 12.4092877,16 8,16 C3.581722,16 0,12.4092877 0,8 Z M6.56122449,7.57142857 L6.56122449,8.6 L8.29737609,8.6 C8.22740525,9.04142857 7.77259475,9.89428571 6.56122449,9.89428571 C5.51603499,9.89428571 4.66326531,9.04571429 4.66326531,8 C4.66326531,6.95428571 5.51603499,6.10571429 6.56122449,6.10571429 C7.15597668,6.10571429 7.55393586,6.35428571 7.78134111,6.56857143 L8.6122449,5.78428571 C8.0787172,5.29571429 7.3877551,5 6.56122449,5 C4.86880466,5 3.5,6.34142857 3.5,8 C3.5,9.65857143 4.86880466,11 6.56122449,11 C8.32798834,11 9.5,9.78285714 9.5,8.06857143 C9.5,7.87142857 9.47813411,7.72142857 9.45189504,7.57142857 L6.56122449,7.57142857 Z M12.5,7.66666667 L11.8333333,7.66666667 L11.8333333,7 L11.1666667,7 L11.1666667,7.66666667 L10.5,7.66666667 L10.5,8.33333333 L11.1666667,8.33333333 L11.1666667,9 L11.8333333,9 L11.8333333,8.33333333 L12.5,8.33333333 L12.5,7.66666667 Z" />
    </g>
  </Icon>
)

CircleFacebookIcon.propTypes = {
  ...Icon.propTypes,
}

export default CircleFacebookIcon
