import React from 'react'
import Icon from '../index'

const ShuffleIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M3.51755737,5.92913038 L2.588427,5 L1.0093689,5 C0.443353176,5 0,4.55228475 0,4 C0,3.44386482 0.451909848,3 1.0093689,3 L2.9906311,3 C3.29535431,3 3.56452536,3.12976452 3.74810423,3.3357497 C3.76859185,3.35325619 3.78855782,3.3717037 3.8079451,3.39109098 L5.20890904,4.79205492 C5.22623618,4.80938206 5.2428074,4.82713998 5.25862399,4.84529061 L11.0483785,10.6350451 C11.4313539,11.0180205 11.4369476,11.6563851 11.0466664,12.0466663 C10.6536643,12.4396685 10.0243809,12.437714 9.6350452,12.0483783 L3.55162121,5.96495435 C3.53991375,5.95324689 3.52855897,5.94130077 3.51755737,5.92913038 Z M13,9.99077797 C13,9.44358641 13.3210526,9.32105255 13.7109581,9.71095812 L15.6440798,11.6440798 C15.8406491,11.8406491 15.838707,12.161293 15.6440798,12.3559202 L13.7109581,14.2890419 C13.3183068,14.6816932 13,14.5490248 13,14.009222 L13,9.99077797 Z" />
    <path d="M10.2506527,3.3357497 C10.4342315,3.12976452 10.7034026,3 11.0081258,3 L12.989388,3 C12.9929296,3 12.996467,3.00001792 13,3.00005366 L13,1.99077797 C13,1.44358641 13.3210526,1.32105255 13.7109581,1.71095812 L15.6440798,3.6440798 C15.8406491,3.8406491 15.838707,4.16129303 15.6440798,4.3559202 L13.7109581,6.28904188 C13.3183068,6.68169321 13,6.54902482 13,6.00922203 L13,4.99994748 C12.9964675,4.99998247 12.9929302,5 12.989388,5 L11.4103299,5 L10.2040614,6.20626848 C9.80987834,6.60045156 9.17647,6.60614025 8.78322304,6.2128933 C8.39269875,5.822369 8.3896143,5.19228848 8.78984785,4.79205492 L10.1908118,3.39109098 C10.2101991,3.3717037 10.230165,3.35325619 10.2506527,3.3357497 Z" />
    <path d="M2.588427,11 L1.0093689,11 C0.443353176,11 0,11.4477153 0,12 C0,12.5561352 0.451909848,13 1.0093689,13 L2.9906311,13 C3.29535431,13 3.56452536,12.8702355 3.74810423,12.6642503 C3.76859185,12.6467438 3.78855782,12.6282963 3.8079451,12.608909 L5.20890904,11.2079451 C5.6091426,10.8077115 5.60605815,10.177631 5.21553385,9.7871067 C4.8222869,9.39385975 4.18887855,9.39954844 3.79469548,9.79373152 L2.588427,11 Z" />
    <path
      d="M11.088427,11.0048852 L9.5093689,11.0048852 C8.94335318,11.0048852 8.5,11.4526005 8.5,12.0048852 C8.5,12.5610204 8.95190985,13.0048852 9.5093689,13.0048852 L11.4906311,13.0048852 C11.7953543,13.0048852 12.0645254,12.8751207 12.2481042,12.6691355 C12.2685919,12.651629 12.2885578,12.6331815 12.3079451,12.6137942 L13.708909,11.2128303 C14.1091426,10.8125967 14.1060581,10.1825162 13.7155339,9.79199191 C13.3222869,9.39874495 12.6888786,9.40443364 12.2946955,9.79861672 L11.088427,11.0048852 Z"
      transform="translate(11.254379, 11.252443) scale(-1, 1) translate(-11.254379, -11.252443)"
    />
  </Icon>
)

ShuffleIcon.propTypes = {
  ...Icon.propTypes,
}

export default ShuffleIcon
