import fetch, { Headers, Response } from 'cross-fetch'

type SuccesfullResponse = {
  result: unknown
}
type ErrorResponse = {
  error: string
  code: number
  handled: boolean
  stack?: unknown
}
type ParsedResponse = {
  response: SuccesfullResponse | ErrorResponse
  status: number
}

class RPCError extends Error {
  private code: number
  private handled: boolean
  private status: number

  constructor(args: {
    message: string
    code: number
    handled: boolean
    status: number
  }) {
    super(args.message)
    this.code = args.code
    this.handled = args.handled
    this.status = args.status
  }
}

export class RPCClient {
  public url: string
  public sendCredentials: RequestCredentials | undefined

  constructor(options?: {
    url?: string
    sendCredentials?: RequestCredentials
  }) {
    this.url = options?.url || 'http://localhost'
    this.sendCredentials = options?.sendCredentials
  }

  public listMethods(): Promise<unknown> {
    return this.call('methods')
  }

  public call(
    name: string,
    args?: unknown,
    headers?: Headers | Record<string, string>,
  ): Promise<unknown> {
    return fetch(`${this.url}/${name}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify({
        args: JSON.stringify(args || {}),
      }),
      credentials: this.sendCredentials,
    })
      .then(
        (response: Response) =>
          new Promise((resolve, reject) => {
            response
              .json()
              .then((parsedResponse: Response) =>
                resolve({
                  response: parsedResponse,
                  status: response.status,
                }),
              )
              .catch((error: unknown) => reject(error))
          }),
      )
      .then((parsedResponse: unknown) => {
        const { response, status } = parsedResponse as ParsedResponse

        if ((response as ErrorResponse).error) {
          const errorResponse = response as ErrorResponse
          throw new RPCError({
            message: errorResponse.error,
            code: errorResponse.code,
            handled: errorResponse.handled,
            status,
          })
        }

        return response
      })
      .then((response: unknown) => (response as SuccesfullResponse).result)
  }
}
