import React from 'react'
import Icon from '../index'

const BufferBottomIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M14.8975838,11.6596673 L13.3049713,10.9257049 C13.1684163,10.8627595 12.9449081,10.8627595 12.8082985,10.9257049 L8.24833642,13.0271856 C8.11178147,13.090131 7.88821853,13.090131 7.75166358,13.0271856 L3.19170155,10.9257049 C3.0550919,10.8627595 2.83158366,10.8627595 2.6950287,10.9257049 L1.10241622,11.6596673 C0.965861261,11.7226127 0.965861261,11.8255895 1.10241622,11.8885349 L7.75166358,14.9528456 C7.88821853,15.015791 8.11178147,15.015791 8.24833642,14.9528456 L14.8975838,11.8885349 C15.0341387,11.8255895 15.0341387,11.7226127 14.8975838,11.6596673" />
  </Icon>
)

BufferBottomIcon.propTypes = {
  ...Icon.propTypes,
}

export default BufferBottomIcon
