import React from 'react'
import Icon from '../index'

const DragIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path
      d="M14,13 C12.8954305,13 12,12.1045695 12,11 C12,9.8954305 12.8954305,9 14,9 C15.1045695,9 16,9.8954305 16,11 C16,12.1045695 15.1045695,13 14,13 Z M8,13 C6.8954305,13 6,12.1045695 6,11 C6,9.8954305 6.8954305,9 8,9 C9.1045695,9 10,9.8954305 10,11 C10,12.1045695 9.1045695,13 8,13 Z M14,7 C12.8954305,7 12,6.1045695 12,5 C12,3.8954305 12.8954305,3 14,3 C15.1045695,3 16,3.8954305 16,5 C16,6.1045695 15.1045695,7 14,7 Z M8,7 C6.8954305,7 6,6.1045695 6,5 C6,3.8954305 6.8954305,3 8,3 C9.1045695,3 10,3.8954305 10,5 C10,6.1045695 9.1045695,7 8,7 Z M2,7 C0.8954305,7 0,6.1045695 0,5 C0,3.8954305 0.8954305,3 2,3 C3.1045695,3 4,3.8954305 4,5 C4,6.1045695 3.1045695,7 2,7 Z M2,13 C0.8954305,13 0,12.1045695 0,11 C0,9.8954305 0.8954305,9 2,9 C3.1045695,9 4,9.8954305 4,11 C4,12.1045695 3.1045695,13 2,13 Z"
      transform="translate(8.000000, 8.000000) rotate(90.000000) translate(-8.000000, -8.000000)"
    />
  </Icon>
)

DragIcon.propTypes = {
  ...Icon.propTypes,
}

export default DragIcon
