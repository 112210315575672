import React from 'react'
import Icon from '../index'

const GoogleIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M5.44217687,7.71428571 L5.44217687,9.6 L8.52866861,9.6 C8.404276,10.4092857 7.595724,11.9728571 5.44217687,11.9728571 C3.5840622,11.9728571 2.06802721,10.4171429 2.06802721,8.5 C2.06802721,6.58285714 3.5840622,5.02714286 5.44217687,5.02714286 C6.49951409,5.02714286 7.20699708,5.48285714 7.61127308,5.87571429 L9.08843537,4.43785714 C8.13994169,3.54214286 6.91156463,3 5.44217687,3 C2.43343052,3 0,5.45928571 0,8.5 C0,11.5407143 2.43343052,14 5.44217687,14 C8.58309038,14 10.6666667,11.7685714 10.6666667,8.62571429 C10.6666667,8.26428571 10.627794,7.98928571 10.5811467,7.71428571 L5.44217687,7.71428571 L5.44217687,7.71428571 Z M16,7.88888889 L14.8148148,7.88888889 L14.8148148,6.66666667 L13.6296296,6.66666667 L13.6296296,7.88888889 L12.4444444,7.88888889 L12.4444444,9.11111111 L13.6296296,9.11111111 L13.6296296,10.3333333 L14.8148148,10.3333333 L14.8148148,9.11111111 L16,9.11111111" />
  </Icon>
)

GoogleIcon.propTypes = {
  ...Icon.propTypes,
}

export default GoogleIcon
