import React from 'react'
import Icon from '../index'

const CirclePlusIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <g fillRule="evenodd">
      <path d="M8,16 C12.418278,16 16,12.418278 16,8 C16,3.581722 12.418278,0 8,0 C3.581722,0 0,3.581722 0,8 C0,12.418278 3.581722,16 8,16 Z M9,7 L9,4.99703014 C9,4.45303631 8.55228475,4 8,4 C7.44386482,4 7,4.4463856 7,4.99703014 L7,7 L4.99703014,7 C4.45303631,7 4,7.44771525 4,8 C4,8.55613518 4.4463856,9 4.99703014,9 L7,9 L7,11.0029699 C7,11.5469637 7.44771525,12 8,12 C8.55613518,12 9,11.5536144 9,11.0029699 L9,9 L11.0029699,9 C11.5469637,9 12,8.55228475 12,8 C12,7.44386482 11.5536144,7 11.0029699,7 L9,7 Z" />
    </g>
  </Icon>
)

CirclePlusIcon.propTypes = {
  ...Icon.propTypes,
}

export default CirclePlusIcon
