import React from 'react'
import ButtonStateless from '../ButtonStateless'
import PseudoClassComponent from '../PseudoClassComponent'

const isNativeComponent = (component: any) => typeof component.type === 'string'

class Button extends PseudoClassComponent {
  // @ts-expect-error TS(2416): Property 'render' in type 'Button' is not assignab... Remove this comment to see the full error message
  render() {
    const { children, ...rest } = this.props as { children: React.ReactNode }
    let hoveredChildren = children
    // string as children isn't clonable
    if (React.isValidElement(children) && !isNativeComponent(children)) {
      hoveredChildren = React.cloneElement(
        children,
        // @ts-expect-error TS(2769): No overload matches this call.
        { hovered: this.state.hovered },
      ) as React.ReactNode
    }
    return (
      <ButtonStateless
        {...rest}
        // @ts-expect-error TS(2339): Property 'hovered' does not exist on type 'Readonl... Remove this comment to see the full error message
        hovered={this.state.hovered}
        // @ts-expect-error TS(2339): Property 'focused' does not exist on type 'Readonl... Remove this comment to see the full error message
        focused={this.state.focused}
        onMouseEnter={() => this.handleMouseEnter()}
        onMouseLeave={() => this.handleMouseLeave()}
        onFocus={() => this.handleFocus()}
        onBlur={() => this.handleBlur()}
      >
        {hoveredChildren as React.ReactNode}
      </ButtonStateless>
    )
  }
}

Button.propTypes = {
  ...ButtonStateless.propTypes,
}

export default Button
