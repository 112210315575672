import React from 'react'
import Icon from '../index'

const MoreIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M2,4 C0.8954305,4 0,3.1045695 0,2 C0,0.8954305 0.8954305,0 2,0 C3.1045695,0 4,0.8954305 4,2 C4,3.1045695 3.1045695,4 2,4 Z M2,3 C2.55228475,3 3,2.55228475 3,2 C3,1.44771525 2.55228475,1 2,1 C1.44771525,1 1,1.44771525 1,2 C1,2.55228475 1.44771525,3 2,3 Z" />
    <path d="M8,4 C6.8954305,4 6,3.1045695 6,2 C6,0.8954305 6.8954305,0 8,0 C9.1045695,0 10,0.8954305 10,2 C10,3.1045695 9.1045695,4 8,4 Z M8,3 C8.55228475,3 9,2.55228475 9,2 C9,1.44771525 8.55228475,1 8,1 C7.44771525,1 7,1.44771525 7,2 C7,2.55228475 7.44771525,3 8,3 Z" />
    <path d="M14,4 C12.8954305,4 12,3.1045695 12,2 C12,0.8954305 12.8954305,0 14,0 C15.1045695,0 16,0.8954305 16,2 C16,3.1045695 15.1045695,4 14,4 Z M14,3 C14.5522847,3 15,2.55228475 15,2 C15,1.44771525 14.5522847,1 14,1 C13.4477153,1 13,1.44771525 13,2 C13,2.55228475 13.4477153,3 14,3 Z" />
  </Icon>
)

MoreIcon.propTypes = {
  ...Icon.propTypes,
}

export default MoreIcon
