import React from 'react'
import Icon from '../index'

const EditIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M0,12.8353339 L3.16356812,15.998902 L0,15.998902 L0,12.8353339 Z M1.05452271,11.7808112 L10.5452271,2.29010681 L13.7087952,5.45367493 L4.21809083,14.9443793 L1.05452271,11.7808112 Z M11.5997498,1.2355841 L12.2903301,0.545003751 C13.0737267,-0.238392873 14.3489904,-0.2332661 15.1240254,0.541768884 L15.4571331,0.874876608 C16.2387432,1.65648666 16.2395801,2.92288997 15.4538982,3.70857187 L14.7633179,4.39915222 L11.5997498,1.2355841 Z" />
  </Icon>
)

EditIcon.propTypes = {
  ...Icon.propTypes,
}

export default EditIcon
