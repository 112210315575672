import { ActionParams, ActionState } from './interfaces'

export const actionTypes = {
  FETCH: 'FETCH',
  FETCH_START: 'FETCH_START',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAIL: 'FETCH_FAIL',
}

export const reducer = (state: unknown, action: ActionState): unknown => {
  switch (action.type) {
    default:
      return state || {}
  }
}

export const actions = {
  fetch: ({ name, args }: ActionParams): ActionState => ({
    type: actionTypes.FETCH,
    name,
    args,
  }),
  fetchStart: ({ name, args, id }: ActionParams): ActionState => ({
    type: `${name}_${actionTypes.FETCH_START}`,
    name,
    args,
    id,
  }),
  fetchSuccess: ({ name, args, id, result }: ActionParams): ActionState => ({
    type: `${name}_${actionTypes.FETCH_SUCCESS}`,
    name,
    args,
    id,
    result,
  }),
  fetchFail: ({ name, args, id, error }: ActionParams): ActionState => ({
    type: `${name}_${actionTypes.FETCH_FAIL}`,
    name,
    args,
    id,
    error,
  }),
}
