import React from 'react'
import Icon from '../index'

const NotificationCloseIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 Z M8,15 C11.8659932,15 15,11.8659932 15,8 C15,4.13400675 11.8659932,1 8,1 C4.13400675,1 1,4.13400675 1,8 C1,11.8659932 4.13400675,15 8,15 Z" />
    <path
      d="M7.5925592,8.6925592 L4.2925592,8.6925592 L4.2925592,8.6925592 C3.98880259,8.6925592 3.7425592,8.44631584 3.7425592,8.1425592 L3.7425592,8.1425592 L3.7425592,8.1425592 C3.7425592,7.83880259 3.98880259,7.59255921 4.2925592,7.59255921 L7.5925592,7.59255921 L7.5925592,4.29255921 L7.5925592,4.29255921 C7.5925592,3.98880259 7.83880259,3.74255921 8.1425592,3.74255921 L8.1425592,3.74255921 L8.1425592,3.74255921 C8.44631584,3.74255921 8.6925592,3.98880259 8.6925592,4.29255921 L8.6925592,7.59255921 L11.9925592,7.59255921 L11.9925592,7.59255921 C12.2963158,7.59255921 12.5425592,7.83880259 12.5425592,8.1425592 L12.5425592,8.1425592 L12.5425592,8.1425592 C12.5425592,8.44631584 12.2963158,8.6925592 11.9925592,8.6925592 L8.6925592,8.6925592 L8.6925592,11.9925592 L8.6925592,11.9925592 C8.6925592,12.2963158 8.44631584,12.5425592 8.1425592,12.5425592 L8.1425592,12.5425592 L8.1425592,12.5425592 C7.83880259,12.5425592 7.5925592,12.2963158 7.5925592,11.9925592 L7.5925592,8.6925592 Z"
      transform="translate(8.142559, 8.142559) rotate(45.000000) translate(-8.142559, -8.142559)"
    />
  </Icon>
)

NotificationCloseIcon.propTypes = {
  ...Icon.propTypes,
}

export default NotificationCloseIcon
