import React from 'react'
import Icon from '../index'

const LockIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M13,7 L13.9931545,7 C14.5492199,7 15,7.4463114 15,7.99754465 L15,15.0026639 C15,15.5534774 14.5500512,16 13.9931545,16 L2.00684547,16 C1.45078007,16 1,15.5468246 1,15.0026639 L1,7.99754465 C1,7.44661595 1.44994876,7 2.00684547,7 L3,7 L3,4.99668583 C3,2.23709245 5.24419519,0 8,0 C10.7614237,0 13,2.24157855 13,4.99668583 L13,7 Z M11,7 L11,4.99668583 C11,3.34461797 9.6553241,2 8,2 C6.34618032,2 5,3.34424456 5,4.99668583 L5,7 L11,7 Z" />
  </Icon>
)

LockIcon.propTypes = {
  ...Icon.propTypes,
}

export default LockIcon
