import React from 'react'
import Icon from '../index'

const ArrowSolidRightIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <polygon points="0 0 0 16 8 8" />
  </Icon>
)

ArrowSolidRightIcon.propTypes = {
  ...Icon.propTypes,
}

export default ArrowSolidRightIcon
