import React, {
  forwardRef,
  ElementRef,
  ComponentPropsWithoutRef,
  CSSProperties,
  ComponentType,
} from 'react'
import clsx from 'clsx'

import { CheckIcon } from '../icons/index'

import styles from './List.module.css'

type ListElement = ElementRef<'ul'>
type ListProps = ComponentPropsWithoutRef<'ul'>

/**
 * Unordered list, renders as an `<ul>` element, supports all native attributes
 */
const List = forwardRef<ListElement, ListProps>(
  (props: ListProps, forwardedRef) => (
    <ul
      {...props}
      ref={forwardedRef}
      className={clsx(styles.unorderedList, props.className)}
    />
  ),
)

List.displayName = 'List'

type ListItemElement = ElementRef<'li'>
type ListItemProps = ComponentPropsWithoutRef<'li'> & {
  /**
   * Set color variation of the list
   */
  color?: 'neutral' | 'primary' | 'success' | 'critical' | 'warning'
  /**
   * Set custom icon for the list item
   */
  icon?: ComponentType
}

const colorMap = {
  neutral: 'var(--color-border-neutral)',
  primary: 'var(--color-bg-brand)',
  success: 'var(--color-bg-success)',
  critical: 'var(--color-bg-critical)',
  warning: 'var(--color-bg-warning)',
}

const ListItem = forwardRef<ListItemElement, ListItemProps>(
  (
    { className, color, children, icon, ...props }: ListItemProps,
    forwardedRef,
  ) => {
    const Icon = icon || CheckIcon

    return (
      <li
        {...props}
        ref={forwardedRef}
        className={clsx(styles.item, className)}
        style={
          {
            '--icon-color': color ? colorMap[color] : undefined,
          } as CSSProperties
        }
      >
        <Icon className={styles.icon} />
        {children}
      </li>
    )
  },
)

ListItem.displayName = 'List.Item'

const ListObject = Object.assign(List, {
  Item: ListItem,
})

export { ListObject as List, ListItem }
export type { ListProps, ListItemProps }
