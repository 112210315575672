import React from 'react'
import Icon from '../index'

const BufferTopIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M1.10241622,4.34038738 L7.75166358,7.40469806 C7.88821853,7.46764349 8.11178147,7.46764349 8.24833642,7.40469806 L14.8975838,4.34038738 C15.0341387,4.27744195 15.0341387,4.17446518 14.8975838,4.11151975 L8.24833642,1.04720908 C8.11178147,0.984263641 7.88821853,0.984263641 7.75166358,1.04720908 L1.10241622,4.11151975 C0.965861261,4.17446518 0.965861261,4.27744195 1.10241622,4.34038738" />
  </Icon>
)

BufferTopIcon.propTypes = {
  ...Icon.propTypes,
}

export default BufferTopIcon
