import React from 'react'
import Icon from '../index'

const SettingsIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M11.4765344,12.8907479 C10.7536018,13.4055629 9.91165661,13.7640946 9,13.9170416 L9,14.9991283 C9,15.5518945 8.55613518,16 8,16 C7.44771525,16 7,15.5553691 7,14.9991283 L7,13.9170416 C6.08834339,13.7640946 5.24639817,13.4055629 4.52346564,12.8907479 L3.75797567,13.6562379 C3.36711097,14.0471026 2.73639271,14.0501012 2.34314575,13.6568542 C1.95262146,13.26633 1.95044047,12.635346 2.34376211,12.2420243 L3.10925208,11.4765344 C2.59443708,10.7536018 2.23590544,9.91165661 2.08295844,9 L1.00087166,9 C0.448105505,9 0,8.55613518 0,8 C0,7.44771525 0.444630861,7 1.00087166,7 L2.08295844,7 C2.23590544,6.08834339 2.59443708,5.24639817 3.10925208,4.52346564 L2.34376211,3.75797567 C1.95044047,3.36465403 1.95262146,2.73367004 2.34314575,2.34314575 C2.73639271,1.9498988 3.36711097,1.95289741 3.75797567,2.34376211 L4.52346564,3.10925208 C5.24639817,2.59443708 6.08834339,2.23590544 7,2.08295844 L7,1.00087166 C7,0.448105505 7.44386482,-7.10542736e-15 8,-7.10542736e-15 C8.55228475,-7.10542736e-15 9,0.444630861 9,1.00087166 L9,2.08295844 C9.91165661,2.23590544 10.7536018,2.59443708 11.4765344,3.10925208 L12.2420243,2.34376211 C12.632889,1.95289741 13.2636073,1.9498988 13.6568542,2.34314575 C14.0473785,2.73367004 14.0495595,3.36465403 13.6562379,3.75797567 L12.8907479,4.52346564 C13.4055629,5.24639817 13.7640946,6.08834339 13.9170416,7 L14.9991283,7 C15.5518945,7 16,7.44386482 16,8 C16,8.55228475 15.5553691,9 14.9991283,9 L13.9170416,9 C13.7640946,9.91165661 13.4055629,10.7536018 12.8907479,11.4765344 L13.6562379,12.2420243 C14.0495595,12.635346 14.0473785,13.26633 13.6568542,13.6568542 C13.2636073,14.0501012 12.632889,14.0471026 12.2420243,13.6562379 L11.4765344,12.8907479 Z M8,11 C9.65685425,11 11,9.65685425 11,8 C11,6.34314575 9.65685425,5 8,5 C6.34314575,5 5,6.34314575 5,8 C5,9.65685425 6.34314575,11 8,11 Z" />
  </Icon>
)

SettingsIcon.propTypes = {
  ...Icon.propTypes,
}

export default SettingsIcon
