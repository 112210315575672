import React from 'react'
import Icon from '../index'

const BufferIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M14.8975838,11.6596673 C15.0341387,11.7226127 15.0341387,11.8255895 14.8975838,11.8885349 L8.24833642,14.9528456 C8.11178147,15.015791 7.88821853,15.015791 7.75166358,14.9528456 L1.10241622,11.8885349 C0.965861261,11.8255895 0.965861261,11.7226127 1.10241622,11.6596673 L2.6950287,10.9257049 C2.83158366,10.8627595 3.0550919,10.8627595 3.19170155,10.9257049 L7.75166358,13.0271856 C7.88821853,13.090131 8.11178147,13.090131 8.24833642,13.0271856 L12.8082985,10.9257049 C12.9449081,10.8627595 13.1684163,10.8627595 13.3049713,10.9257049 L14.8975838,11.6596673 Z M14.8975838,7.88556618 C15.0341387,7.94851162 15.0341387,8.05154307 14.8975838,8.1144885 L8.24833642,11.1787445 C8.11178147,11.2416899 7.88821853,11.2416899 7.75166358,11.1787445 L1.10241622,8.1144885 C0.965861261,8.05154307 0.965861261,7.94851162 1.10241622,7.88556618 L2.6950287,7.1516585 C2.83158366,7.08871306 3.0550919,7.08871306 3.19170155,7.1516585 L7.75166358,9.25308448 C7.88821853,9.31602991 8.11178147,9.31602991 8.24833642,9.25308448 L12.8082985,7.1516585 C12.9449081,7.08871306 13.1684163,7.08871306 13.3049713,7.1516585 L14.8975838,7.88556618 Z M1.10241622,4.34038738 C0.965861261,4.27744195 0.965861261,4.17446518 1.10241622,4.11151975 L7.75166358,1.04720908 C7.88821853,0.984263641 8.11178147,0.984263641 8.24833642,1.04720908 L14.8975838,4.11151975 C15.0341387,4.17446518 15.0341387,4.27744195 14.8975838,4.34038738 L8.24833642,7.40469806 C8.11178147,7.46764349 7.88821853,7.46764349 7.75166358,7.40469806 L1.10241622,4.34038738 Z" />
  </Icon>
)

BufferIcon.propTypes = {
  ...Icon.propTypes,
}

export default BufferIcon
