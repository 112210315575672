export const fontFamily = '"Roboto", sans-serif'

export const fontSize = '1rem'
export const fontSizeLarge = '1.25rem'
export const fontSizeExtraLarge = '2rem'
export const fontSizeSmall = '0.75rem'
export const fontSizeExtraSmall = '0.5rem'
export const fontSizeMini = '0.875rem'

// https://www.w3.org/TR/css-fonts-3/#font-weight-prop
export const fontWeightThin = 100
export const fontWeightExtraLight = 200
export const fontWeightLight = 300
export const fontWeight = 400 // Normal
export const fontWeightMedium = 500
export const fontWeightSemiBold = 600
export const fontWeightBold = 700
export const fontWeightExtraBold = 800
export const fontWeightBlack = 900

export const lineHeight = 1.5
