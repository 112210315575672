import React from 'react'
import Icon from '../index'

const RefreshIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M1.67363116,11 C2.7970951,13.3649473 5.20760404,15 8,15 C11.1710206,15 13.8495721,12.8914889 14.7101213,10 L12.5839563,10 C11.8123997,11.7659048 10.050319,13 8,13 C6.36418217,13 4.91184014,12.2144467 3.99963381,11 L1.67363116,11 Z M1.28987868,6 C2.15042787,3.10851105 4.82897936,1 8,1 C10.792396,1 13.2029049,2.63505267 14.3263688,5 L12.0003662,5 C11.0881599,3.7855533 9.63581783,3 8,3 C5.94968096,3 4.18760031,4.23409522 3.41604369,6 L1.28987868,6 Z M0.316626728,6.37995207 C0.141758615,6.17011034 0.21484375,6 0.497698784,6 L4.50230122,6 C4.77717266,6 4.85110044,6.17867947 4.68337327,6.37995207 L2.81662673,8.62004793 C2.64175861,8.82988966 2.35110044,8.82132053 2.18337327,8.62004793 L0.316626728,6.37995207 Z M11.3166267,9.62004793 L13.1833733,7.37995207 C13.3511004,7.17867947 13.6417586,7.17011034 13.8166267,7.37995207 L15.6833733,9.62004793 C15.8511004,9.82132053 15.7771727,10 15.5023012,10 L11.4976988,10 C11.2148438,10 11.1417586,9.82988966 11.3166267,9.62004793 Z" />
  </Icon>
)

RefreshIcon.propTypes = {
  ...Icon.propTypes,
}

export default RefreshIcon
