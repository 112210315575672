import { Primitive } from '@radix-ui/react-primitive'
import clsx from 'clsx'
import React, { useState } from 'react'
import LightboxComponent, {
  ControllerRef,
  LightboxExternalProps,
  Slide,
  ThumbnailsRef,
  ZoomRef,
} from 'yet-another-react-lightbox'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import Video from 'yet-another-react-lightbox/plugins/video'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/styles.css'

import useId from '../../helpers/useId'
import usePortalContainer from '../../helpers/usePortalContainer'
import { useControllableState } from '../../hooks/useControllableState'
import { CloseIcon } from '../icons'
import { NextButton, PrevButton, ZoomControls } from './Controls'
import { Counter } from './Counter'

import styles from './Lightbox.module.css'

/* -------------------------------------------------------------------------------------------------
 * LightboxContext
 * ----------------------------------------------------------------------------------------------- */

interface LightboxContextType {
  open: boolean
  contentId: string
  onOpenToggle: (options?: { index?: number }) => void
}
const LightboxContext = React.createContext<LightboxContextType | null>(null)

function useLightboxContext(): LightboxContextType {
  const context = React.useContext(LightboxContext)

  if (!context) {
    throw new Error(
      'Lightbox compound components cannot be rendered outside the Lightbox component',
    )
  }

  return context
}

/* -------------------------------------------------------------------------------------------------
 * Lightbox
 * ----------------------------------------------------------------------------------------------- */

interface LightboxProps
  extends React.ComponentPropsWithoutRef<'div'>,
    Partial<LightboxExternalProps> {
  slides: Slide[]
  defaultOpen?: boolean
  onOpenChange?: (open: boolean) => void
}

const Lightbox = React.forwardRef<ControllerRef, LightboxProps>(
  (
    {
      open: openProp,
      defaultOpen,
      onOpenChange,
      children,
      index: providedIndex = 0,
      ...restProps
    }: LightboxProps,
    forwardedRef,
  ) => {
    const [open = false, setOpen] = useControllableState({
      prop: openProp,
      defaultProp: defaultOpen,
      onChange: onOpenChange,
    })
    const portal = usePortalContainer()
    const [index, setIndex] = useState(providedIndex || 0)

    const thumbnailsRef = React.useRef<ThumbnailsRef>(null)

    // Need the following for tracking whether zooming in/out is possible
    const zoomRef = React.useRef<ZoomRef>(null)
    const [zoomLevel, setZoomLevel] = React.useState(1)
    // The default helps for the first render until the ref is assigned
    const maxZoom: number = zoomRef.current?.maxZoom || 5
    const onOpenToggle = React.useCallback(
      (options?: { index?: number }) => {
        if (options?.index !== undefined) setIndex(options.index)
        setOpen((prevOpen) => !prevOpen)
      },
      [setOpen],
    )

    return (
      <LightboxContext.Provider
        value={{ open, contentId: useId(), onOpenToggle }}
      >
        <LightboxComponent
          portal={{ root: portal }}
          controller={{ ref: forwardedRef, closeOnBackdropClick: true }}
          open={open}
          index={index}
          close={() => setOpen(false)}
          className={styles.lightbox}
          carousel={{
            // This allows thumbnails to cycle from left to right, instead of an infinite loop
            finite: true,
            // The preloads the thumbnails as well
            preload: 10,
          }}
          zoom={{ ref: zoomRef, maxZoomPixelRatio: 3 }}
          plugins={[Counter, Thumbnails, Video, Zoom]}
          animation={{
            // Disable swipe animation when changing slides
            swipe: 0,
          }}
          on={{
            exited: () => setOpen(false),
            zoom: ({ zoom }) => setZoomLevel(zoom),
            entering: () => {
              if (thumbnailsRef.current && restProps.slides.length <= 1)
                thumbnailsRef.current.hide()
            },
          }}
          thumbnails={{
            ref: thumbnailsRef,
            width: 80,
            height: 80,
            border: 2,
            imageFit: 'cover',
            padding: 0,
            vignette: false,
          }}
          render={{
            iconClose: () => <CloseIcon />,
            buttonNext: restProps.slides.length <= 1 ? () => null : NextButton,
            buttonPrev: restProps.slides.length <= 1 ? () => null : PrevButton,
            // Replace default zoom button with custom controls
            buttonZoom: () => null,
            controls: () => (
              <ZoomControls
                zoomLevel={zoomLevel}
                maxZoom={maxZoom}
                zoomIn={zoomRef.current?.zoomIn}
                zoomOut={zoomRef.current?.zoomOut}
              />
            ),
          }}
          {...restProps}
        />
        {children}
      </LightboxContext.Provider>
    )
  },
)

Lightbox.displayName = 'Lightbox'

/* -------------------------------------------------------------------------------------------------
 * LightboxTrigger
 * ----------------------------------------------------------------------------------------------- */

function getState(open: boolean) {
  return open ? 'open' : 'closed'
}

type LightboxTriggerElement = React.ElementRef<typeof Primitive.button>
type PrimitiveButtonProps = React.ComponentPropsWithoutRef<
  typeof Primitive.button
>
type LightboxTriggerProps = PrimitiveButtonProps & {
  index?: number
}

const LightboxTrigger = React.forwardRef<
  LightboxTriggerElement,
  LightboxTriggerProps
>(
  (
    { className, index, ...triggerProps }: LightboxTriggerProps,
    forwardedRef,
  ) => {
    const context = useLightboxContext()

    const onOpen = React.useCallback(() => {
      context.onOpenToggle({ index })
    }, [context, index])

    const onKeyDown = React.useCallback(
      (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault()
          onOpen()
        }
      },
      [onOpen],
    )

    return (
      <Primitive.button
        ref={forwardedRef}
        type="button"
        role="button"
        className={clsx(styles.trigger, className)}
        aria-haspopup="dialog"
        aria-expanded={context.open}
        aria-controls={context.contentId}
        data-state={getState(context.open)}
        {...triggerProps}
        onClick={onOpen}
        onKeyDown={onKeyDown}
      />
    )
  },
)

LightboxTrigger.displayName = 'LightboxTrigger'

const LightboxObject = Object.assign(Lightbox, {
  Trigger: LightboxTrigger,
})

export { LightboxObject as Lightbox }
export type {
  LightboxProps,
  LightboxTriggerProps,
  ControllerRef,
  ThumbnailsRef,
  ZoomRef,
}
