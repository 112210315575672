import colors from '../style/color'

export const calcActiveStyles = (styleSwitches = {}) => {
  const activeStyles = ['default']
  Object.keys(styleSwitches).forEach((styleKey) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (styleSwitches[styleKey]) {
      activeStyles.push(styleKey)
    }
  })
  return activeStyles
}

export const mergeStyles = (styles = []) =>
  styles.reduce(
    (p, c) => ({
      ...p,
      // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
      ...c,
    }),
    {},
  )

export const calculateStyles = (styles = {}, switches: any) => {
  // @ts-expect-error TS(2339): Property 'default' does not exist on type '{}'.
  if (!styles.default) {
    throw new Error('missing `default` style')
  }
  const activeStyles = calcActiveStyles(switches).map((activeStyle) => {
    if (!(activeStyle in styles)) {
      throw new Error(`style \`${activeStyle}\` is not an available style`)
    }
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return styles[activeStyle]
  })
  // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
  return mergeStyles(activeStyles)
}

export const parseColor = (color: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (colors[color]) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return colors[color]
  }
  return color
}
