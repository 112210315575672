import React from 'react'
import Icon from '../index'

const ExportIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M0,7.99703014 C0,7.4463856 0.443353176,7 1.0093689,7 L2.9906311,7 C3.54809015,7 4,7.44386482 4,8 L4,8 C4,8.55228475 3.55613518,9 3,9 L2.24523926,9 C2.10979736,9 2,9.11539841 2,9.25021791 L2,12.7497821 C2,12.8879736 2.10374069,13 2.25063705,13 L13.7493629,13 C13.887786,13 14,12.8846016 14,12.7497821 L14,9.25021791 C14,9.11202638 13.8924713,9 13.7547607,9 L13,9 C12.4477153,9 12,8.55613518 12,8 L12,8 C12,7.44771525 12.4433532,7 13.0093689,7 L14.9906311,7 C15.5480902,7 16,7.45303631 16,7.99703014 L16,14.0029699 C16,14.5536144 15.5553691,15 14.9991283,15 L1.00087166,15 C0.448105505,15 0,14.5469637 0,14.0029699 L0,7.99703014 Z M7,5 L4.99895656,5 C4.44724809,5 4.31387329,4.68612671 4.70591205,4.29408795 L7.64502668,1.35497332 C7.84107303,1.15892697 8.15192032,1.15192032 8.35497332,1.35497332 L11.2940879,4.29408795 C11.6839524,4.68395241 11.5573397,5 11.0010434,5 L9,5 L9,10.009222 C9,10.5564136 8.55613518,11 8,11 L8,11 C7.44771525,11 7,10.5490248 7,10.009222 L7,5 Z" />
  </Icon>
)

ExportIcon.propTypes = {
  ...Icon.propTypes,
}

export default ExportIcon
