import { RPCClient } from '@buffer-mono/legacy-rpc-utils'
import { actions, actionTypes } from './reducer'

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export const middleware = (store) => {
  let counter = 0
  const rpc = new RPCClient({
    url: '/rpc',
    sendCredentials: 'same-origin',
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  return (next) => (action) => {
    next(action)
    switch (action.type) {
      case actionTypes.FETCH: {
        const id = counter++ // eslint-disable-line no-plusplus
        const args = action.args || {}

        store.dispatch(
          actions.fetchStart({
            name: action.name,
            args,
            id,
          }),
        )

        rpc
          .call(action.name, args, {
            'x-buffer-client-id':
              (
                window as Window &
                  typeof globalThis & { xBufferClientId: string }
              ).xBufferClientId || 'unknown-frontend',
          })
          .then((result) =>
            store.dispatch(
              actions.fetchSuccess({
                name: action.name,
                args,
                id,
                result,
              }),
            ),
          )
          .catch((error) => {
            console.log('error', error)
            store.dispatch(
              actions.fetchFail({
                name: action.name,
                args,
                id,
                error: error.message,
              }),
            )
          })
        break
      }
      default:
        break
    }
  }
}
