import React from 'react'
import Icon from '../index'

const CircleCheckmarkIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M8,15.976 C3.59497684,15.976 0.024,12.4050232 0.024,8 C0.024,3.59497684 3.59497684,0.024 8,0.024 C12.4050232,0.024 15.976,3.59497684 15.976,8 C15.976,12.4050232 12.4050232,15.976 8,15.976 Z M4.84201948,7.87755339 C4.44877253,8.27080035 4.45446122,8.90420869 4.84864429,9.29839176 L6.24960824,10.6993557 C6.64984179,11.0995893 7.28084969,11.0955774 7.67251796,10.7039092 L11.2039092,7.17251796 C11.6034584,6.7729687 11.5965048,6.14675734 11.2059805,5.75623305 C10.8127336,5.36298609 10.1823522,5.36564781 9.79190308,5.75609692 L6.96333983,8.58466017 L6.25623305,7.87755339 C5.86298609,7.48430644 5.23254378,7.4870291 4.84201948,7.87755339 Z" />
  </Icon>
)

CircleCheckmarkIcon.propTypes = {
  ...Icon.propTypes,
}

export default CircleCheckmarkIcon
