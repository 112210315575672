// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const GbpWhatsNewIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'gbp-whats-new'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M12 8V12M12 15H12.01M15.0902 2L16.5377 5.26133L20.0902 5.62868L19.3422 9.11704L22 11.5L19.3422 13.883L20.0902 17.3713L16.5377 17.7387L15.0902 21L12 19.2114L8.90983 21L7.4623 17.7387L3.90983 17.3713L4.65784 13.883L2 11.5L4.65784 9.11704L3.90983 5.62868L7.4623 5.26133L8.90983 2L12 3.78858L15.0902 2Z"
          stroke="currentColor"
          strokeLinecap="round"
        />
      </svg>
    )
  },
)
GbpWhatsNewIcon.displayName = 'GbpWhatsNewIcon'
export { GbpWhatsNewIcon }
