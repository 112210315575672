export * from './client'
export * from './getClientName/getClientName'

export enum Product {
  Buffer = 'buffer',
  Analyze = 'analyze',
  Publish = 'publish',
  Engage = 'engage',
  Marketing = 'marketing',
  Account = 'account',
}

import * as BufferTrackerReact from './clients/js/segment'
import * as BufferTrackerNode from './clients/node/segment'
export { BufferTrackerReact, BufferTrackerNode }
