import React from 'react'
import Icon from '../index'

const BufferMiddleIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M14.8975838,7.88556618 L13.3049713,7.1516585 C13.1684163,7.08871306 12.9449081,7.08871306 12.8082985,7.1516585 L8.24833642,9.25308448 C8.11178147,9.31602991 7.88821853,9.31602991 7.75166358,9.25308448 L3.19170155,7.1516585 C3.0550919,7.08871306 2.83158366,7.08871306 2.6950287,7.1516585 L1.10241622,7.88556618 C0.965861261,7.94851162 0.965861261,8.05154307 1.10241622,8.1144885 L7.75166358,11.1787445 C7.88821853,11.2416899 8.11178147,11.2416899 8.24833642,11.1787445 L14.8975838,8.1144885 C15.0341387,8.05154307 15.0341387,7.94851162 14.8975838,7.88556618" />
  </Icon>
)

BufferMiddleIcon.propTypes = {
  ...Icon.propTypes,
}

export default BufferMiddleIcon
