import React from 'react'
import PropTypes from 'prop-types'
import Text from '../Text'
import Link from '../Link'
import colors from '../style/color'

const linkDataToElement = (options: any, link: any, key: any) => (
  <Link
    // @ts-expect-error TS(2322): Type '{ children: any; href: any; key: any; unstyl... Remove this comment to see the full error message
    href={link.url}
    key={key}
    unstyled={options.unstyled}
    newTab={options.newTab}
    className={link.className}
  >
    {link.displayString}
  </Link>
)

const calulateLinkifiedText = (
  options: any,
  links: any,
  curString: any,
  calculatedElements = [],
) => {
  // nothing left to calculate, return all the caluculated pairs
  if ((curString && curString.length === 0) || !curString) {
    calculatedElements.reverse()
    return calculatedElements
  }
  // consume whats left of the string
  if ((links && links.length === 0) || !links) {
    // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    calculatedElements.push(curString)
    return calulateLinkifiedText(options, links, '', calculatedElements)
  }
  const lastLink = links[links.length - 1]
  // link is outside of bounds
  if (curString.length <= lastLink.indices[0]) {
    return calulateLinkifiedText(
      options,
      links.slice(0, -1),
      curString,
      calculatedElements,
    )
  }
  // text at the end that's not a link
  if (curString.length !== lastLink.indices[1]) {
    // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    calculatedElements.push(curString.substring(lastLink.indices[1]))
    return calulateLinkifiedText(
      options,
      links,
      curString.substring(0, lastLink.indices[1]),
      calculatedElements,
    )
  }
  // found a link!
  calculatedElements.push(
    // @ts-expect-error TS(2345): Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
    linkDataToElement(options, lastLink, calculatedElements.length),
  )
  return calulateLinkifiedText(
    options,
    links.slice(0, -1),
    curString.substring(0, lastLink.indices[0]),
    calculatedElements,
  )
}

const LinkifiedText = ({
  children,
  color,
  links,
  newTab,
  size,
  unstyled,
  whitespace,
}: any) => {
  const options = { unstyled, newTab }
  return (
    <Text whitespace={whitespace} size={size} color={color}>
      {calulateLinkifiedText(options, links, children)}
    </Text>
  )
}

LinkifiedText.propTypes = {
  children: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(colors)),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      rawString: PropTypes.string,
      displayString: PropTypes.string,
      expandedUrl: PropTypes.string,
      indices: PropTypes.arrayOf(PropTypes.number),
    }),
  ),
  newTab: PropTypes.bool,
  size: PropTypes.string,
  unstyled: PropTypes.bool,
  whitespace: PropTypes.string,
}

LinkifiedText.defaultProps = {
  links: [],
}

export default LinkifiedText
