import React from 'react'
import Icon from '../index'

const CircleTwitterIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <g fillRule="evenodd">
      <path d="M0,8 C0,3.581722 3.59071231,0 8,0 L8,0 C12.418278,0 16,3.59071231 16,8 L16,8 C16,12.418278 12.4092877,16 8,16 L8,16 C3.581722,16 0,12.4092877 0,8 L0,8 Z M12,5.32763533 C11.7037037,5.46723647 11.3888889,5.56695157 11.0555556,5.60683761 C11.3981481,5.38746439 11.6574074,5.03846154 11.7777778,4.62962963 C11.462963,4.82905983 11.1111111,4.97863248 10.7407407,5.05840456 C10.4351852,4.71937322 10.0092593,4.5 9.53703704,4.5 C8.62962963,4.5 7.89814815,5.28774929 7.89814815,6.26495726 C7.89814815,6.4045584 7.90740741,6.54415954 7.93518519,6.67378917 C6.57407407,6.59401709 5.37037037,5.92592593 4.55555556,4.85897436 C4.41666667,5.11823362 4.33333333,5.38746439 4.33333333,5.71652422 C4.33333333,6.32478632 4.62037037,6.86324786 5.06481481,7.18233618 C4.7962963,7.17236467 4.5462963,7.09259259 4.32407407,6.96296296 L4.32407407,6.98290598 C4.32407407,7.84045584 4.88888889,8.55840456 5.63888889,8.71794872 C5.5,8.75783476 5.35185185,8.77777778 5.2037037,8.77777778 C5.10185185,8.77777778 5,8.76780627 4.89814815,8.74786325 C5.10185185,9.44586895 5.71296296,9.96438746 6.42592593,9.97435897 C5.87037037,10.4529915 5.15740741,10.8119658 4.38888889,10.8119658 C4.25925926,10.8119658 4.12962963,10.8019943 4,10.7820513 C4.72222222,11.2806268 5.59259259,11.5 6.51851852,11.5 C9.53703704,11.5 11.1851852,8.80769231 11.1851852,6.47435897 L11.1851852,6.24501425 C11.5,5.9957265 11.7777778,5.68660969 12,5.32763533 L12,5.32763533 Z" />
    </g>
  </Icon>
)

CircleTwitterIcon.propTypes = {
  ...Icon.propTypes,
}

export default CircleTwitterIcon
