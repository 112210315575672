import React from 'react'
import Icon from '../index'

const CircleLinkedInIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <g fillRule="evenodd">
      <path d="M0,8 C0,3.581722 3.59071231,0 8,0 C12.418278,0 16,3.59071231 16,8 C16,12.418278 12.4092877,16 8,16 C3.581722,16 0,12.4092877 0,8 Z M4.96185043,4 C4.42938642,4 4,4.43141524 4,4.962986 C4,5.49486781 4.42938642,5.92628305 4.96185043,5.92628305 C5.49214111,5.92628305 5.92245896,5.49486781 5.92245896,4.962986 C5.92245896,4.43141524 5.49214111,4 4.96185043,4 Z M4.13164125,6.6562986 L4.13164125,12 L5.79112819,12 L5.79112819,6.6562986 L4.13164125,6.6562986 Z M6.83090775,6.6562986 L6.83090775,12 L8.486669,12 L8.486669,9.35707621 C8.486669,8.65972006 8.61831024,7.9844479 9.48111926,7.9844479 C10.3318198,7.9844479 10.3423759,8.78164852 10.3423759,9.40124417 L10.3423759,12 L12,12 L12,9.06936236 C12,7.63017107 11.6898358,6.52379471 10.0110995,6.52379471 C9.20479683,6.52379471 8.66395001,6.96671851 8.44289207,7.38693624 L8.41991695,7.38693624 L8.41991695,6.6562986 L6.83090775,6.6562986 Z" />
    </g>
  </Icon>
)

CircleLinkedInIcon.propTypes = {
  ...Icon.propTypes,
}

export default CircleLinkedInIcon
