import React, { CSSProperties, forwardRef } from 'react'
import { Slot } from '@radix-ui/react-slot'
import clsx from 'clsx'

import { Space } from '../../styles/tokens/tokens'
import styles from './Card.module.css'

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Size of the padding around the card
   * @default small
   */
  padding?: 'small' | 'large' | 'none' | Space
  /**
   * Render the card as a child element
   */
  asChild?: boolean
}

interface CardCssVariables extends CSSProperties {
  '--card-padding': `var(--${Space})` | 'none'
}

const paddingMap = {
  small: 'var(--space-200)',
  large: 'var(--space-400)',
  none: 'none',
} as const

function isSpacePadding(padding: string): padding is Space {
  return padding.startsWith('space-')
}

/**
 * A container for content representing a single entity
 */
export const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    { asChild, padding = 'small', className, ...props }: CardProps,
    forwardedRef,
  ) => {
    const Tag = asChild ? Slot : 'div'
    const cardPadding = isSpacePadding(padding)
      ? (`var(--${padding})` as const)
      : paddingMap[padding]

    return (
      <Tag
        style={
          {
            '--card-padding': cardPadding,
          } satisfies CardCssVariables as CardCssVariables
        }
        className={clsx(styles.container, className)}
        ref={forwardedRef}
        {...props}
      />
    )
  },
)

Card.displayName = 'Card'
