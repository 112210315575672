// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const AllPostsIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'all-posts'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 5C3 3.89543 3.89543 3 5 3H16C17.1046 3 18 3.89543 18 5V16C18 17.1046 17.1046 18 16 18H5C3.89543 18 3 17.1046 3 16V5ZM5 5H16V16H5L5 5Z"
          fill="currentColor"
        />
        <path
          d="M20 9.60001C20 9.04772 20.4477 8.60001 21 8.60001C21.5523 8.60001 22 9.04772 22 9.60001V19.4C22 20.0896 21.7261 20.7509 21.2385 21.2385C20.7509 21.7261 20.0896 22 19.4 22H9.6C9.04771 22 8.6 21.5523 8.6 21C8.6 20.4477 9.04771 20 9.6 20H19.4C19.5591 20 19.7117 19.9368 19.8243 19.8243C19.9368 19.7117 20 19.5591 20 19.4V9.60001Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
AllPostsIcon.displayName = 'AllPostsIcon'
export { AllPostsIcon }
