import React from 'react'
import Icon from '../index'

const PabloIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M14.787916,4.17370169 C15.3982182,5.31031723 15.7450655,6.61399751 15.7450655,8 C15.7450655,12.418278 12.2204125,16 7.87253276,16 C3.52465298,16 0,12.418278 0,8 C0,3.581722 3.52465298,0 7.87253276,0 C9.60276488,0 11.2026283,0.567212195 12.5018784,1.52863538 L11.4646748,3.13728606 C10.4643144,2.3729689 9.22047903,1.92 7.87253276,1.92 C4.56814412,1.92 1.88940786,4.64210872 1.88940786,8 C1.88940786,11.3578913 4.56814412,14.08 7.87253276,14.08 C11.1769214,14.08 13.8556577,11.3578913 13.8556577,8 C13.8556577,7.16773624 13.6910998,6.37452934 13.3932441,5.6521455 C11.9318042,7.19211745 10.494696,8.68395438 10.494696,8.68395438 L9.39047904,7.75227814 C9.39047904,7.75227814 12.9307127,2.88115286 14.2016372,1.23370858 C14.3041259,1.10085696 15.8980477,1.51489069 16,2.85769757 C15.7014598,3.19169966 15.2741911,3.65411706 14.787916,4.17370169 Z M4.76500248,10.9456533 C7.4803179,11.1729897 6.50908212,8.25761877 9.02106348,8.23016715 L10.1092911,9.14849535 C10.380777,12.4621247 6.37049434,13.2016816 4.76500248,10.9456533 Z" />
  </Icon>
)

PabloIcon.propTypes = {
  ...Icon.propTypes,
}

export default PabloIcon
