import React from 'react'
import Icon from '../index'

const TiktokIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M8.302.013C9.175 0 10.042.008 10.909 0c.052 1.02.42 2.06 1.166 2.782.746.74 1.8 1.078 2.826 1.192V6.66c-.961-.032-1.927-.232-2.8-.646a8.11 8.11 0 01-1.08-.62c-.005 1.948.008 3.894-.013 5.834a5.075 5.075 0 01-.901 2.628c-.872 1.278-2.386 2.112-3.94 2.138-.954.054-1.907-.206-2.72-.685-1.346-.794-2.293-2.247-2.431-3.807a11.15 11.15 0 01-.008-.992A4.998 4.998 0 012.729 7.2c1.104-.962 2.65-1.42 4.099-1.149.013.988-.026 1.974-.026 2.962-.662-.214-1.435-.154-2.013.247a2.31 2.31 0 00-.91 1.167c-.137.338-.098.714-.09 1.073.159 1.094 1.21 2.013 2.334 1.914.744-.008 1.458-.44 1.846-1.073.125-.221.266-.448.273-.708.066-1.193.04-2.38.048-3.573.006-2.688-.008-5.368.012-8.048z" />
  </Icon>
)

TiktokIcon.propTypes = {
  ...Icon.propTypes,
}

export default TiktokIcon
