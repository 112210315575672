import React from 'react'
import Icon from '../index'

const TwitterIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M16,2.65527066 C15.4074074,2.93447293 14.7777778,3.13390313 14.1111111,3.21367521 C14.7962963,2.77492877 15.3148148,2.07692308 15.5555556,1.25925926 C14.9259259,1.65811966 14.2222222,1.95726496 13.4814815,2.11680912 C12.8703704,1.43874644 12.0185185,1 11.0740741,1 C9.25925926,1 7.7962963,2.57549858 7.7962963,4.52991453 C7.7962963,4.80911681 7.81481481,5.08831909 7.87037037,5.34757835 C5.14814815,5.18803419 2.74074074,3.85185185 1.11111111,1.71794872 C0.833333333,2.23646724 0.666666667,2.77492877 0.666666667,3.43304843 C0.666666667,4.64957265 1.24074074,5.72649573 2.12962963,6.36467236 C1.59259259,6.34472934 1.09259259,6.18518519 0.648148148,5.92592593 L0.648148148,5.96581197 C0.648148148,7.68091168 1.77777778,9.11680912 3.27777778,9.43589744 C3,9.51566952 2.7037037,9.55555556 2.40740741,9.55555556 C2.2037037,9.55555556 2,9.53561254 1.7962963,9.4957265 C2.2037037,10.8917379 3.42592593,11.9287749 4.85185185,11.9487179 C3.74074074,12.9059829 2.31481481,13.6239316 0.777777778,13.6239316 C0.518518519,13.6239316 0.259259259,13.6039886 0,13.5641026 C1.44444444,14.5612536 3.18518519,15 5.03703704,15 C11.0740741,15 14.3703704,9.61538462 14.3703704,4.94871795 L14.3703704,4.49002849 C15,3.99145299 15.5555556,3.37321937 16,2.65527066 L16,2.65527066 Z" />
  </Icon>
)

TwitterIcon.propTypes = {
  ...Icon.propTypes,
}

export default TwitterIcon
