import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import clsx from 'clsx'
import { Command, useCommandState } from 'cmdk'
import { Slot } from '@radix-ui/react-slot'

import { Button } from '../Button'
import { Popover } from '../Popover'
import { Input } from '../Input'
import { useControllableState } from '../../hooks/useControllableState'
import { mergeRefs, useMergeRefs } from '../../hooks/useMergeRefs'
import { Checkbox } from '../Checkbox'
import { Text } from '../Text'
import { CheckIcon, PlusIcon } from '../icons'

import { ScrollArea } from '../ScrollArea'

import styles from './Combobox.module.css'

type ComboboxContextValue = {
  onSelectItem: (itemValue: string) => void
  selected?: string[] | string
  setSearch: (value: string) => void
  search: string
  values: string[] | string
  registerValue: (value: string) => void
  clearSelection: () => void
  multiple: boolean
  listInnerRef: React.RefObject<HTMLDivElement>
  open?: boolean
  setOpen: (open: boolean) => void
}

// @ts-expect-error - This is a private API
const ComboboxContext = React.createContext<ComboboxContextValue>(undefined)

type ComboboxRootProps = React.ComponentPropsWithoutRef<typeof Popover> &
  (
    | {
        value?: string
        defaultValue?: string
        onChange?: (value: string) => void
        multiple: false
      }
    | {
        value?: string[]
        defaultValue?: string[]
        onChange?: (value: string[]) => void
        multiple?: true
      }
  )

/**
 * Combobox is used to select one or multiple items from a list of options. It is a customizable component that allows you to have full control over the rendering of the items and the search input.
 */
const ComboboxRoot = ({
  multiple = true as const,
  ...props
}: ComboboxRootProps) => {
  const [selected, setSelected] = useControllableState<
    ComboboxRootProps['value']
  >({
    prop: props.value as ComboboxRootProps['value'],
    defaultProp: props.defaultValue as ComboboxRootProps['defaultValue'],
    onChange: props.onChange as (value: ComboboxRootProps['value']) => void,
  })
  const [open, setOpen] = useControllableState<boolean>({
    prop: props.open,
    defaultProp: props.defaultOpen,
    onChange: props.onOpenChange,
  })
  const [search, setSearch] = React.useState('')
  const [values, setValues] = React.useState<string[] | string>(
    multiple ? [] : '',
  )

  const registerValue = useCallback((value: string) => {
    setValues((values) =>
      Array.isArray(values) && !values.includes(value)
        ? [...values, value]
        : values,
    )
  }, [])

  const clearSelection = useCallback(() => {
    if (multiple) {
      setSelected([])
    } else {
      setSelected(undefined)
    }
  }, [multiple, setSelected])

  const onSelectItem = useCallback(
    (itemValue: string) => {
      if (multiple) {
        setSelected((current) => {
          const currentArray: string[] = Array.isArray(current) ? current : []
          if (currentArray.includes(itemValue)) {
            return currentArray.filter((item) => item !== itemValue)
          } else {
            return [...currentArray, itemValue]
          }
        })
      } else {
        setSelected((current) =>
          current === itemValue ? undefined : itemValue,
        )

        /* when single selection, close the popover on selected */
        setOpen(false)
      }
    },
    [multiple, setSelected, setOpen],
  )
  const { children, ...rootProps } = props

  const listInnerRef = useRef<HTMLDivElement>(null)

  const contextValue: ComboboxContextValue = useMemo(
    () => ({
      onSelectItem,
      selected,
      setSearch,
      search,
      registerValue,
      values,
      clearSelection,
      multiple,
      listInnerRef,
      open,
      setOpen,
    }),
    [
      onSelectItem,
      selected,
      setSearch,
      search,
      registerValue,
      values,
      clearSelection,
      multiple,
      open,
      setOpen,
    ],
  )

  return (
    <Popover {...rootProps} open={open} onOpenChange={setOpen}>
      <ComboboxContext.Provider value={contextValue}>
        {children}
      </ComboboxContext.Provider>
    </Popover>
  )
}
ComboboxRoot.displayName = 'Combobox'

/**
 * Combobox Trigger wrapper
 */
type ComboboxTriggerProps = Omit<
  React.ComponentPropsWithoutRef<typeof Popover.Trigger>,
  'asChild'
>

const ComboboxTrigger = React.forwardRef<
  React.ElementRef<typeof Popover.Trigger>,
  ComboboxTriggerProps
>((props, forwardedRef) => <Popover.Trigger {...props} ref={forwardedRef} />)

ComboboxTrigger.displayName = 'Combobox.Trigger'

/**
 * Combobox Anchor wrapper
 */
type ComboboxAnchorProps = Omit<
  React.ComponentPropsWithoutRef<typeof Popover.Anchor>,
  'asChild'
>

const ComboboxAnchor = React.forwardRef<
  React.ElementRef<typeof Popover.Anchor>,
  ComboboxAnchorProps
>((props, forwardedRef) => <Popover.Anchor {...props} ref={forwardedRef} />)

ComboboxAnchor.displayName = 'Combobox.Anchor'

const ComboboxInternalContent = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const { setSearch } = React.useContext(ComboboxContext)

  useEffect(
    function resetSearchOnUnMount() {
      return () => setSearch?.('')
    },
    [setSearch],
  )

  return <Command loop>{children}</Command>
}

const ComboboxInput = forwardRef<
  HTMLInputElement,
  React.ComponentPropsWithoutRef<typeof Input>
>(({ className, ...props }, forwardedRef) => {
  const innerRef = useRef<HTMLInputElement>(null)
  const { search, setSearch, selected } = React.useContext(ComboboxContext)

  const ref = useMergeRefs(forwardedRef, innerRef)

  useEffect(
    function focusSearchAfterSelection() {
      if (innerRef.current) {
        innerRef.current.focus()
      }
    },
    [selected, innerRef],
  )

  const value = useCommandState((state) => state.value)
  const [id, setId] = React.useState<string | undefined>(undefined)
  // Update the aria-activedescendant when the value changes
  useEffect(() => {
    if (!value) return
    const nextId = document.querySelector(
      `[cmdk-item=""][data-value="${value}"]`,
    )?.id
    setId(nextId)
  }, [value])

  return (
    <Command.Input
      asChild
      className={clsx(styles.search, className)}
      value={search}
      onValueChange={setSearch}
    >
      <Input ref={ref} {...props} aria-activedescendant={id} />
    </Command.Input>
  )
})

ComboboxInput.displayName = 'Combobox.Input'

type ComboboxContentElement = React.ElementRef<typeof Popover.Content>
type ComboboxContentProps = React.ComponentPropsWithoutRef<
  typeof Popover.Content
>
const ComboboxContent = forwardRef<
  ComboboxContentElement,
  ComboboxContentProps
>(({ children, className, size, style, ...props }, forwardedRef) => {
  const innerRef = useRef<HTMLDivElement>(null)
  const ref = useMergeRefs(forwardedRef, innerRef)
  const { open } = React.useContext(ComboboxContext)
  const [widthStyle, setStyle] = React.useState<React.CSSProperties | null>(
    null,
  )

  useEffect(() => {
    if (
      innerRef.current &&
      typeof size === 'undefined' &&
      open &&
      !widthStyle
    ) {
      // Sets the width of the content max content within the popover and keep it
      // like that although the content is filtered.
      setStyle({
        '--combobox-content-width': `${innerRef.current.offsetWidth}px`,
      } as React.CSSProperties)
    }
  }, [size, open, widthStyle])

  return (
    <Popover.Content
      ref={ref}
      className={clsx(styles.popover, styles.content, className)}
      size={size}
      style={{
        ...style,
        ...widthStyle,
      }}
      {...props}
    >
      <ComboboxInternalContent>{children}</ComboboxInternalContent>
    </Popover.Content>
  )
})

ComboboxContent.displayName = 'Combobox.Content'

type CommandGroupProps = React.ComponentPropsWithoutRef<typeof Command.Group>
const ComboboxGroup: React.FC<CommandGroupProps> = Command.Group
ComboboxGroup.displayName = 'Combobox.Group'

type ComboboxSeparatorProps = React.ComponentPropsWithoutRef<
  typeof Command.Separator
>
const ComboboxSeparator: React.FC<ComboboxSeparatorProps> = forwardRef<
  HTMLDivElement,
  ComboboxSeparatorProps
>((_, ref) => {
  return <Command.Separator ref={ref} className={styles.separator} />
})
ComboboxSeparator.displayName = 'Combobox.Separator'

type ComboboxItemProps = React.ComponentPropsWithoutRef<typeof Command.Item>
const ComboboxItem: React.FC<ComboboxItemProps> = React.memo(
  forwardRef<HTMLDivElement, ComboboxItemProps>(
    (
      { children, className, value, onSelect, keywords, disabled, ...props },
      forwardedRef,
    ) => {
      const { onSelectItem, selected, registerValue, multiple } =
        React.useContext(ComboboxContext)
      const innerRef = useRef<HTMLDivElement>(null)
      const ref = useMergeRefs(forwardedRef, innerRef)
      const isSelected =
        !!value &&
        (Array.isArray(selected)
          ? selected?.includes(value)
          : selected === value)

      useEffect(() => {
        if (value) registerValue?.(value)
      }, [value, registerValue])

      return (
        <Command.Item
          {...props}
          className={clsx(styles.item, className)}
          onSelect={() => {
            if (!value) return
            onSelectItem?.(value)
            onSelect?.(value)
          }}
          value={value}
          keywords={keywords}
          disabled={disabled}
          ref={ref}
        >
          <div className={styles.indicator} aria-hidden>
            {/* if multi-selection, we render checkbox */}
            {multiple ? (
              <Checkbox
                tabIndex={-1}
                checked={isSelected}
                disabled={disabled}
              />
            ) : (
              isSelected && <CheckIcon />
            )}
          </div>
          {children}
        </Command.Item>
      )
    },
  ),
)
ComboboxItem.displayName = 'Combobox.Item'

type ComboboxCreateProps = React.ComponentPropsWithoutRef<typeof Command.Item>

const ComboboxCreate = React.memo(
  forwardRef<HTMLDivElement, ComboboxCreateProps>(
    ({ className, onSelect, ...props }, forwardedRef) => {
      const { search, values } = React.useContext(ComboboxContext)

      if (
        values?.includes(search?.trim() ?? '') ||
        search?.trim().length === 0
      ) {
        // The item already exists or is empty
        return null
      }

      return (
        <Command.Group>
          <Command.Item
            ref={forwardedRef}
            className={clsx(styles.create, className)}
            onSelect={() => {
              onSelect?.(search ?? '')
            }}
            {...props}
          >
            <PlusIcon />
            <Text weight="medium">Create &lsquo;{search}&rsquo;</Text>
          </Command.Item>
        </Command.Group>
      )
    },
  ),
)
ComboboxCreate.displayName = 'Combobox.Create'

type ComboboxEmptyProps = React.ComponentPropsWithoutRef<typeof Command.Empty>
const ComboboxEmpty: React.FC<ComboboxEmptyProps> = forwardRef<
  HTMLDivElement,
  ComboboxEmptyProps
>(({ children, className, ...props }, ref) => {
  return (
    <Command.Empty
      ref={ref}
      aria-live="polite"
      className={clsx(styles.empty, className)}
      {...props}
    >
      {children}
    </Command.Empty>
  )
})
ComboboxEmpty.displayName = 'Combobox.Empty'

type ComboboxLoadingProps = React.ComponentPropsWithoutRef<
  typeof Command.Loading
>
const ComboboxLoading: React.FC<ComboboxLoadingProps> = Command.Loading
ComboboxLoading.displayName = 'Combobox.Loading'

type ComboboxFooterProps = React.ComponentPropsWithoutRef<'footer'>
const ComboboxFooter = forwardRef<HTMLDivElement, ComboboxFooterProps>(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <footer
        className={clsx(styles.footer, className)}
        ref={forwardedRef}
        {...props}
      >
        {children}
      </footer>
    )
  },
)
ComboboxFooter.displayName = 'Combobox.Footer'

type ComboboxClearProps = React.ComponentPropsWithoutRef<typeof Button>
const ComboboxClear = forwardRef<HTMLButtonElement, ComboboxClearProps>(
  ({ children, ...props }, forwardedRef) => {
    const { clearSelection } = React.useContext(ComboboxContext)
    return (
      <Slot ref={forwardedRef} {...props} onClick={clearSelection}>
        {children}
      </Slot>
    )
  },
)
ComboboxClear.displayName = 'Combobox.Clear'

type ComboboxListProps = React.ComponentPropsWithoutRef<typeof Command.List>
const ComboboxList: React.FC<ComboboxListProps> = forwardRef<
  HTMLDivElement,
  ComboboxListProps
>(({ children, ...props }, forwardedRef) => {
  const { multiple, listInnerRef } = React.useContext(ComboboxContext)
  const ref = mergeRefs(forwardedRef, listInnerRef)

  return (
    <div className={styles.listWrapper}>
      <ScrollArea
        visibility="hover"
        scrollbars="vertical"
        className={styles.list}
      >
        <Command.List {...props} aria-multiselectable={multiple} ref={ref}>
          {children}
        </Command.List>
      </ScrollArea>
    </div>
  )
})
ComboboxList.displayName = 'Combobox.List'

const Combobox = Object.assign(ComboboxRoot, {
  Input: ComboboxInput,
  Trigger: ComboboxTrigger,
  Content: ComboboxContent,
  Group: ComboboxGroup,
  Separator: ComboboxSeparator,
  Item: ComboboxItem,
  Empty: ComboboxEmpty,
  Loading: ComboboxLoading,
  Create: ComboboxCreate,
  Footer: ComboboxFooter,
  Clear: ComboboxClear,
  List: ComboboxList,
  Anchor: ComboboxAnchor,
})

export { Combobox }

export type {
  ComboboxRootProps as ComboboxProps,
  ComboboxItemProps,
  ComboboxContentProps,
  ComboboxAnchorProps,
}
