import React from 'react'
import Icon from '../index'

const SupportIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M15.6779554,2.42770277 C15.8852289,2.9116277 16,3.44468369 16,4.00457656 C16,6.21624313 14.209139,8.00915312 12,8.00915312 C11.4417517,8.00915312 10.9102135,7.89466322 10.427534,7.68785764 L2.54993413,15.5654575 C1.96757782,16.1478138 1.02469502,16.1491172 0.434824591,15.5592467 C-0.150961847,14.9734603 -0.146964313,14.0197153 0.428613784,13.4441372 L8.31287775,5.55987323 C8.11138988,5.08169812 8,4.55616047 8,4.00457656 C8,1.79291 9.790861,2.22044605e-16 12,2.22044605e-16 C12.559976,2.22044605e-16 13.0930765,0.115199686 13.5769462,0.323218319 C13.524899,0.363059242 13.4748722,0.406790595 13.427256,0.454406852 L12.7044065,1.17725634 C12.1303527,1.75131015 12.1248734,2.70653695 12.7106598,3.29232339 C13.3005302,3.88219382 14.243394,3.88090952 14.8257268,3.29857669 L15.5485763,2.5757272 C15.5955069,2.5287966 15.6386374,2.47931841 15.6779554,2.42770277 Z" />
  </Icon>
)

SupportIcon.propTypes = {
  ...Icon.propTypes,
}

export default SupportIcon
