import React from 'react'
import Icon from '../index'

const CircleShopifyIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <g fillRule="evenodd">
      <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM10.8234 4.93977L11.5883 2.24205C11.5883 2.24205 10.924 1.92357 9.61572 2.01724C6.23421 2.20459 4.70448 4.41522 4.84538 6.58838C4.94704 8.0393 5.86652 8.64644 6.67164 9.17807C7.30111 9.59371 7.86068 9.9632 7.90483 10.6537C7.92496 11.0284 7.6633 11.5717 6.95882 11.6091C5.87191 11.6841 4.52333 10.7286 4.52333 10.7286L4 12.7894C4 12.7894 5.34858 14.1382 7.80419 13.9884C9.83712 13.876 11.2461 12.3585 11.1052 10.1291C10.9833 8.41659 9.68858 7.64467 8.66784 7.03611C8.00247 6.63942 7.45353 6.31215 7.42176 5.83901C7.42176 5.6142 7.42176 4.7337 8.93136 4.64003C9.95789 4.56509 10.8234 4.93977 10.8234 4.93977Z" />
    </g>
  </Icon>
)

CircleShopifyIcon.propTypes = {
  ...Icon.propTypes,
}

export default CircleShopifyIcon
