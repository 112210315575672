import React from 'react'
import Icon from '../index'

const CalenderIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M0,2.99188419 C0,1.89179693 0.894705057,1 2,1 L2,2.49755925 C2,3.05119199 2.44386482,3.5 3,3.5 C3.55228475,3.5 4,3.0530501 4,2.49755925 L4,1 L12,1 L12,2.49755925 C12,3.05119199 12.4438648,3.5 13,3.5 C13.5522847,3.5 14.0018163,3.0471957 14.0035508,2.49689974 C14.0035508,2.49689974 14.0106489,1.17132023 14,1 C14.258891,0.86101765 14.0132088,0.993687199 14.0132088,0.993687199 C15.1104833,0.997173663 16,1.89339733 16,2.99188419 L16,14.0081158 C16,15.1082031 15.1054862,16 14.0059397,16 L1.99406028,16 C0.892771196,16 0,15.1066027 0,14.0081158 L0,2.99188419 Z M2,5.25964642 L2,13.4903342 C2,13.7775269 2.22401312,14 2.50034732,14 L13.4996527,14 C13.7709994,14 14,13.7718148 14,13.4903342 L14,5.25964642 C14,5.11379325 13.887786,5 13.7493629,5 L2.25063705,5 C2.10374069,5 2,5.11624766 2,5.25964642 Z M2.5,0.495388985 C2.5,0.221793203 2.73193359,0 3,0 C3.27614237,0 3.5,0.215752602 3.5,0.495388985 L3.5,2.50461102 C3.5,2.7782068 3.26806641,3 3,3 C2.72385763,3 2.5,2.7842474 2.5,2.50461102 L2.5,0.495388985 Z M12.5,0.495388985 C12.5,0.221793203 12.7319336,0 13,0 C13.2761424,0 13.5,0.215752602 13.5,0.495388985 L13.5,2.50461102 C13.5,2.7782068 13.2680664,3 13,3 C12.7238576,3 12.5,2.7842474 12.5,2.50461102 L12.5,0.495388985 Z M3.5,6.12149811 C3.5,6.05439656 3.55747223,6 3.62722683,6 L12.3727732,6 C12.4430386,6 12.5,6.05615234 12.5,6.12359619 L12.5,10.8764038 C12.5,10.9446641 12.4434547,11 12.3669128,11 L8.5,11 L8.5,13 L3.62359619,13 C3.5553359,13 3.5,12.9394846 3.5,12.8785019 L3.5,6.12149811 Z M4.5,7 L4.5,8 L5.5,8 L5.5,7 L4.5,7 Z M6.5,7 L6.5,8 L7.5,8 L7.5,7 L6.5,7 Z M8.5,7 L8.5,8 L9.5,8 L9.5,7 L8.5,7 Z M10.5,7 L10.5,8 L11.5,8 L11.5,7 L10.5,7 Z M4.5,9 L4.5,10 L5.5,10 L5.5,9 L4.5,9 Z M6.5,9 L6.5,10 L7.5,10 L7.5,9 L6.5,9 Z M8.5,9 L8.5,10 L9.5,10 L9.5,9 L8.5,9 Z M10.5,9 L10.5,10 L11.5,10 L11.5,9 L10.5,9 Z M4.5,11 L4.5,12 L5.5,12 L5.5,11 L4.5,11 Z M6.5,11 L6.5,12 L7.5,12 L7.5,11 L6.5,11 Z" />
  </Icon>
)

CalenderIcon.propTypes = {
  ...Icon.propTypes,
}

export default CalenderIcon
