import React, {
  forwardRef,
  CSSProperties,
  ElementRef,
  ComponentPropsWithoutRef,
} from 'react'
import clsx from 'clsx'

import styles from './OrderedList.module.css'

type OrderedListElement = ElementRef<'ol'>
type OrderedListProps = ComponentPropsWithoutRef<'ol'> & {
  /**
   * Set the starting number of the list
   */
  start?: number
  /**
   * Set the list to be reversed
   */
  reversed?: boolean
}

/**
 * Ordered list, renders as an `<ol>` element, supports all native attributes
 */
const OrderedList = forwardRef<OrderedListElement, OrderedListProps>(
  (
    { start, className, reversed, ...props }: OrderedListProps,
    forwardedRef,
  ) => (
    <ol
      ref={forwardedRef}
      style={
        {
          counterSet: start
            ? `ordered-list-counter ${reversed ? start + 1 : start - 1}`
            : undefined,
          '--counter-increment': reversed ? '-1' : '1',
        } as CSSProperties
      }
      className={clsx(styles.orderedList, className)}
      {...props}
    />
  ),
)

OrderedList.displayName = 'OrderedList'

type OrderedListItemElement = ElementRef<'li'>
type OrderedListItemProps = ComponentPropsWithoutRef<'li'>

/**
 * Ordered list item, renders as an `<li>` element
 */
const OrderedListItem = forwardRef<
  OrderedListItemElement,
  OrderedListItemProps
>(({ className, ...props }: OrderedListItemProps, forwardedRef) => {
  return (
    <li
      {...props}
      ref={forwardedRef}
      className={clsx(styles.item, className)}
    />
  )
})

OrderedListItem.displayName = 'OrderedList.Item'

const OrderedListObject = Object.assign(OrderedList, {
  Item: OrderedListItem,
})

export { OrderedListObject as OrderedList, OrderedListItem }
export type { OrderedListProps, OrderedListItemProps }
