import React from 'react'
import Icon from '../index'

const ShopifyIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <g>
      <path d="M10.7615 0.370956L9.74169 3.96792C9.74169 3.96792 8.58769 3.46834 7.21898 3.56825C5.20618 3.69315 5.20618 4.86716 5.20618 5.1669C5.31353 6.76555 9.84904 7.11526 10.1174 10.8871C10.3053 13.8596 8.42666 15.8828 5.71609 16.0327C2.44193 16.2325 0.643829 14.4341 0.643829 14.4341L1.3416 11.6864C1.3416 11.6864 3.1397 12.9603 4.58892 12.8604C5.52823 12.8104 5.87711 12.0861 5.85028 11.5865C5.71609 9.48825 2.01253 9.61314 1.771 6.16606C1.58314 3.26851 3.62278 0.320998 8.13145 0.0712095C9.87588 -0.0536849 10.7615 0.370956 10.7615 0.370956Z" />
    </g>
  </Icon>
)

ShopifyIcon.propTypes = {
  ...Icon.propTypes,
}

export default ShopifyIcon
