import React, { forwardRef } from 'react'
import clsx from 'clsx'
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'

import styles from './ToggleGroup.module.css'

type ToggleGroupSize = 'large' | 'medium'

type ToggleGroupElement = React.ElementRef<
  typeof ToggleGroupPrimitive.ToggleGroup
>
type ToggleGroupItem = React.ElementRef<typeof ToggleGroupPrimitive.Item>
type ToggleGroupProps = {
  /**
   * Children are the elements in the toggle group
   */
  children: React.ReactNode
  /**
   * The controlled value of the pressed items
   */
  value?: string
  /**
   * The value of the item to show as pressed. Use when you do not need
   * to control the state of the items
   */
  defaultValue?: string
  /**
   * When true, prevents the user from interacting with the toggle group and
   * all its items
   * @default false
   */
  disabled?: boolean
  /**
   * Controls size of the ToggleGroup
   * @default medium
   */
  size?: ToggleGroupSize
  /**
   * Event handler called when the pressed state of an item changes
   */
  onChange?: (value: string) => void
  /**
   * Class name applied to the root element
   */
  className?: string
}

const ToggleGroup = forwardRef<ToggleGroupElement, ToggleGroupProps>(
  (
    {
      children,
      value,
      defaultValue,
      disabled = false,
      size = 'medium',
      onChange,
      className,
    }: ToggleGroupProps,
    ref,
  ) => {
    return (
      <ToggleGroupPrimitive.Root
        ref={ref}
        type="single"
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        onValueChange={onChange}
        className={clsx(styles.base, styles[size], className)}
      >
        {children}
      </ToggleGroupPrimitive.Root>
    )
  },
)

ToggleGroup.displayName = 'ToggleGroup'

type ToggleGroupItemProps = ToggleGroupPrimitive.ToggleGroupItemProps & {
  /**
   * Children are the elements in the toggle group
   */
  children: React.ReactNode
  /**
   * The controlled value of the pressed items
   */
  value: string
  /**
   * When true, prevents the user from interacting with the toggle group and all its items
   */
  disabled?: boolean
  /**
   * When true, the item will be rendered as a child of the ToggleGroup
   */
  asChild?: boolean
}

const ToggleGroupItem = forwardRef<ToggleGroupItem, ToggleGroupItemProps>(
  (
    {
      children,
      value,
      disabled = false,
      asChild = false,
      ...props
    }: ToggleGroupItemProps,
    ref,
  ) => {
    return (
      <ToggleGroupPrimitive.Item
        id={value}
        ref={ref}
        value={value}
        disabled={disabled}
        className={styles.item}
        asChild={asChild}
        {...props}
      >
        {children}
      </ToggleGroupPrimitive.Item>
    )
  },
)

ToggleGroupItem.displayName = 'ToggleGroup.Item'

const ToggleGroupObject = Object.assign(ToggleGroup, { Item: ToggleGroupItem })

export { ToggleGroupObject as ToggleGroup }
export type { ToggleGroupProps, ToggleGroupItemProps, ToggleGroupSize }
