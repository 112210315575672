import React from 'react'
import Icon from '../index'

const GifIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M3.15491775,7.6017025 L5.87242145,7.6017025 L5.87242145,11.1531987 C5.43199623,11.2968835 5.0173468,11.3976176 4.6284607,11.4554039 C4.23957461,11.5131902 3.84210462,11.5420829 3.43603882,11.5420829 C2.40213282,11.5420829 1.61265903,11.238319 1.06759379,10.6307821 C0.522528537,10.0232451 0.25,9.15099765 0.25,8.01401341 C0.25,6.90826499 0.566258044,6.04616899 1.19878362,5.42769954 C1.8313092,4.80923009 2.70824199,4.5 3.82960832,4.5 C4.53241452,4.5 5.21022188,4.64055913 5.86305075,4.92168161 L5.38045957,6.08364871 C4.88068628,5.83376206 4.36061749,5.70882061 3.82023762,5.70882061 C3.19239742,5.70882061 2.68950808,5.91965931 2.31155453,6.34134302 C1.93360098,6.76302674 1.74462704,7.32994857 1.74462704,8.04212551 C1.74462704,8.78553829 1.89689943,9.353241 2.20144878,9.74525068 C2.50599813,10.1372604 2.94875939,10.3332623 3.52974584,10.3332623 C3.8327334,10.3332623 4.14040172,10.3020269 4.45276003,10.2395552 L4.45276003,8.81052311 L3.15491775,8.81052311 L3.15491775,7.6017025 Z M8.03601252,11.4483758 L8.03601252,4.59839238 L9.48847139,4.59839238 L9.48847139,11.4483758 L8.03601252,11.4483758 Z M13.1748016,11.4483758 L11.7457695,11.4483758 L11.7457695,4.59839238 L15.6720938,4.59839238 L15.6720938,5.78847158 L13.1748016,5.78847158 L13.1748016,7.55484899 L15.4987358,7.55484899 L15.4987358,8.74024284 L13.1748016,8.74024284 L13.1748016,11.4483758 Z" />
  </Icon>
)

GifIcon.propTypes = {
  ...Icon.propTypes,
}

export default GifIcon
