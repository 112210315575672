import React from 'react'
import Icon from '../index'

const PricingIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M0.709946632,8.70994663 C0.317853934,8.31785393 0.323517799,7.6764822 0.709946632,7.29005337 L7.29005337,0.709946632 C7.68214607,0.317853934 8.45303631,-1.36510312e-15 8.99703014,-1.3349054e-15 L15.0029699,-1.00150877e-15 C15.5536144,-9.70941855e-16 16,0.453036308 16,0.997030139 L16,7.00296986 C16,7.5536144 15.6764822,8.3235178 15.2900534,8.70994663 L8.70994663,15.2900534 C8.31785393,15.6821461 7.6764822,15.6764822 7.29005337,15.2900534 L0.709946632,8.70994663 Z M2.75,8 L8,13.25 L14,7.25 L14,2 L8.75,2 L2.75,8 Z M11.5,6 C10.6715729,6 10,5.32842712 10,4.5 C10,3.67157288 10.6715729,3 11.5,3 C12.3284271,3 13,3.67157288 13,4.5 C13,5.32842712 12.3284271,6 11.5,6 Z" />
  </Icon>
)

PricingIcon.propTypes = {
  ...Icon.propTypes,
}

export default PricingIcon
