import React from 'react'
import Icon from '../index'

const GoogleBusinessIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M14 4.55c0 .959-.784 1.75-1.75 1.75s-1.75-.791-1.75-1.75c0 .959-.784 1.75-1.75 1.75S7 5.509 7 4.55c0 .959-.784 1.75-1.75 1.75S3.5 5.509 3.5 4.55c0 .959-.784 1.75-1.75 1.75S0 5.509 0 4.55L.973.756S1.176 0 1.89 0h10.22c.714 0 .917.756.917.756L14 4.55zm-.7 2.59v5.46c0 .77-.63 1.4-1.4 1.4H2.1c-.77 0-1.4-.63-1.4-1.4V7.14a2.773 2.773 0 002.8-.406 2.802 2.802 0 001.75.616c.665 0 1.274-.231 1.75-.616a2.802 2.802 0 001.75.616c.665 0 1.274-.231 1.75-.616a2.787 2.787 0 001.75.616c.371 0 .728-.077 1.05-.21zm-1.4 3.591c0-.14 0-.287-.035-.441l-.021-.112H9.765v.819h1.267a.98.98 0 01-.217.434 1.238 1.238 0 01-.882.357c-.35 0-.693-.147-.945-.392a1.293 1.293 0 01.014-1.806c.483-.49 1.281-.49 1.785-.021l.098.091.588-.595-.112-.098A2.134 2.134 0 009.905 8.4h-.007c-.567 0-1.099.217-1.498.609a2.092 2.092 0 00-.644 1.491c0 .56.217 1.078.616 1.463a2.24 2.24 0 001.554.637h.014a2 2 0 001.421-.56c.329-.336.539-.84.539-1.309z" />
  </Icon>
)

GoogleBusinessIcon.propTypes = {
  ...Icon.propTypes,
}

export default GoogleBusinessIcon
