import React from 'react'
import Icon from '../index'

const LinkedInIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M1.92370086,0 C2.98428222,0 3.84491792,0.862830482 3.84491792,1.92597201 C3.84491792,2.98973561 2.98428222,3.8525661 1.92370086,3.8525661 C0.858772849,3.8525661 0,2.98973561 0,1.92597201 C0,0.862830482 0.858772849,0 1.92370086,0 L1.92370086,0 Z M0.263282493,5.3125972 L3.58225637,5.3125972 L3.58225637,16 L0.263282493,16 L0.263282493,5.3125972 Z M5.6618155,5.3125972 L8.8398339,5.3125972 L8.8398339,6.77387247 L8.88578414,6.77387247 C9.32790003,5.93343701 10.4095937,5.04758942 12.0221989,5.04758942 C15.3796717,5.04758942 16,7.26034215 16,10.1387247 L16,16 L12.6847518,16 L12.6847518,10.8024883 C12.6847518,9.56329705 12.6636395,7.9688958 10.9622385,7.9688958 C9.23662048,7.9688958 8.97333799,9.31944012 8.97333799,10.7141524 L8.97333799,16 L5.6618155,16 L5.6618155,5.3125972" />
  </Icon>
)

LinkedInIcon.propTypes = {
  ...Icon.propTypes,
}

export default LinkedInIcon
