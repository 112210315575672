import React from 'react'
import Icon from '../index'

const PinterestIcon = ({ color, size }: any) => (
  <Icon color={color} size={size}>
    <path d="M7.7248357,0 C3.33186737,0 1,2.81015219 1,5.87249753 C1,7.29461784 1.79409827,9.06639971 3.06559767,9.6301856 C3.2591705,9.7161211 3.36141721,9.67846053 3.4053137,9.50107822 C3.43936025,9.36594924 3.61173428,8.70785883 3.68839255,8.40065471 C3.71376686,8.30257393 3.7012403,8.21837347 3.61719458,8.12141536 C3.19867899,7.63703306 2.86153251,6.74165043 2.86153251,5.90821894 C2.86153251,3.76881274 4.56118331,1.69860401 7.45631764,1.69860401 C9.95809656,1.69860401 11.7075324,3.32188689 11.7075324,5.64500319 C11.7075324,8.27011848 10.3191184,10.0879299 8.50897696,10.0879299 C7.50952875,10.0879299 6.7613612,9.3016507 7.00118595,8.33390671 C7.28918977,7.18132994 7.844641,5.93536721 7.844641,5.10479343 C7.844641,4.35842011 7.4249477,3.73717378 6.55643952,3.73717378 C5.53547133,3.73717378 4.71439278,4.74461956 4.71439278,6.09437851 C4.71439278,6.95352942 5.01952694,7.53405334 5.01952694,7.53405334 C5.01952694,7.53405334 4.01129944,11.6006806 3.82704123,12.3575662 C3.51259245,13.642006 3.86804698,15.7232373 3.89941691,15.9050083 C3.91536954,16.0025787 4.02907216,16.0355445 4.09331093,15.9534873 C4.19245277,15.8274418 5.40838563,14.089136 5.75227718,12.8321506 C5.87433085,12.3785908 6.38470788,10.5256703 6.38470788,10.5256703 C6.71832123,11.129158 7.68618537,11.6394638 8.7176459,11.6394638 C11.7870814,11.6394638 14,8.94729433 14,5.60458696 C14,2.3985395 11.256148,0 7.7248357,0" />
  </Icon>
)

PinterestIcon.propTypes = {
  ...Icon.propTypes,
}

export default PinterestIcon
